import { axios } from '@/plugins/vueaxios'
import CostCenter from '@/models/economy/CostCenter'

export default class CostCenterService {
  public static loadOne(id: number): Promise<CostCenter> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/economy/cost-center/' + id)
        .then((response) => {
          resolve(new CostCenter(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static loadAll(): Promise<CostCenter[]> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/economy/cost-center')
        .then((response) => {
          const result = []
          response.data.data.forEach((v) => {
            result.push(new CostCenter(v))
          })
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static create(data: CostCenter): Promise<CostCenter> {
    return new Promise((resolve, reject) => {
      axios
        .post('/v4/site/economy/cost-center', data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static update(data: CostCenter): Promise<CostCenter> {
    return new Promise((resolve, reject) => {
      axios
        .put('/v4/site/economy/cost-center/' + data.id, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}