export default class CostCenter {
  public id: number
  public name: string
  public erpCode: string
  public isDefault: boolean

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.name = data.name ? (data.name as string) : ''
    this.erpCode = data.erpCode ? (data.erpCode as string) : ''
    this.isDefault = data.isDefault ? (data.isDefault as boolean) : false
  }

  public clone(): CostCenter {
    const result = new CostCenter()
    Object.assign(result, this)
    return result
  }
}